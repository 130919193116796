/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "date-fns";

//--- Material Control
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

//--- Action
import * as opinionFormAction from "./../../../../../redux/store/opinion-form/opinion-form.store";
import * as appActions from "./../../../../../core/app.store";

//--- Material Icon
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import * as viVN from "./../../../../../language/vi-VN.json";
import {
  NotificationMessageType,
  MaxSizeImageUpload,
} from "./../../../../../utils/configuration";
import ShowNotification from "./../../../../../components/react-notifications/react-notifications";
import * as postsAction from "../../../../../redux/store/posts/posts.store";
import FileManagement from "./../../../../../components/file_management/file_management";
// import FormTemplateComponent from "../form-template/form-template.view";

import "./add-dialog-comment.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  accordionSummary: {
    minHeight: "50px !important",
    borderBottom: "1px solid #e3e6f0",
    height: 50,
    paddingLeft: 0,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 2,
  },
}));

function AddDialogComment(props) {
  const { showLoading, isOpen, onClose, setDataComment, dataComment, reflectPostId, postsModels, setPostsModels } = props;
  const classes = useStyles();

  const [content, setContent] = useState();

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);

  const { register, handleSubmit, errors, setError, clearErrors, setValue } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    showLoading(true);
    const item = {
      reflectPostId,
      content,
    }
    postsAction.CreateComment(item).then(
      (res) => {
        showLoading(false);
        if (res && res.content) {
          const dataCmt = [...dataComment]
          dataCmt.unshift(item)
          setDataComment(dataCmt);
          const tmp = [...postsModels];
          const tmp1 = tmp.find((item) => item.id === reflectPostId);
          const tmp2 = tmp.filter((item) => item.id !== reflectPostId);
          if(tmp1) {
            tmp1.comments = dataCmt;
            tmp2.unshift(tmp1)
            setPostsModels(tmp2)
          }
          onClose();
        } else {
          ShowNotification(
            viVN.Errors[(res && res.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  return (
    <div>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
          <DialogTitle disableTypography>
            <Typography variant="h6">Thêm bình luận</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <DialogContent className="pt-4 pb-2" dividers>
            <div className="form-group">
          <label className="text-dark">
            Nội dung bình luận
          </label>
          <textarea
            name="brief"
            value={content}
            onChange={(e)=>setContent(e.target.value)}
            rows="5"
            ref={register({ required: false })}
            className="form-control"
          ></textarea>
        </div>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={onClose}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["doc", "docx", "txt", "pdf"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDialogComment);
