import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";
import * as configuration from "../../../utils/configuration";
import dateformat from "dateformat";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import SunEditor from "suneditor-react";
//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  NotificationMessageType,
  MaxSizeImageUpload,
} from "./../../../utils/configuration";
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from "@material-ui/core";
import DialogComment from "./../dialog/dialog-comments/dialog-comment.view";
import * as postsAction from "../../../redux/store/posts/posts.store";
import FooterPagination from "../../../components/footer-pagination/footer-pagination";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
    // overflow: "hidden",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#00923F",
  },
  title: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const headCells = [
  { id: "title", hideSortIcon: false, label: "Tiêu đề" },
  { id: "title", hideSortIcon: false, label: "Nội dung" },
  { id: "image_Url", hideSortIcon: true, label: "Trạng thái" },
  { id: "viewCount", hideSortIcon: false, label: "Hình ảnh" },
  { id: "viewCount", hideSortIcon: false, label: "Video" },
  { id: "status", hideSortIcon: false, label: "Quận/Huyện" },
  { id: "isHot", hideSortIcon: false, label: "Địa chỉ" },
  { id: "isHot", hideSortIcon: false, label: "Trả lời" },
  { id: "isHot", hideSortIcon: false, label: "Comment" },
  { id: "createdBy", hideSortIcon: false, label: "Người tạo" },
  { id: "createdDate", hideSortIcon: false, label: "Ngày tạo" },
  { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              "pt-3 pb-3 text-nowrap" +
              (headCell.id === "planningName" ? "MuiTableCell-freeze" : "")
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListPosts(props) {
  const {
    editAction,
    deleteAction,
    restoreAction,
    postsModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListAll,
    title,
    categoryId,
    isDelete,
    order,
    page,
    rowsPerPage,
    orderBy,
    setPostsModels,
    setPostsModel,
  } = props;

  //--- Config table
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openVideoDialog, setOpenVideoDialog] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [videoUrl, setVideoUrl] = React.useState("");
  const [replyText, setReplyText] = React.useState("");
  const [reflectPostId, setReflectPostId] = React.useState("");
  const [checkEdit, setCheckEdit] = React.useState(false);
  const [commentId, setCommentId] = React.useState("");
  const [showDialogComment, setShowDialogComment] = React.useState(false);
  const [replyDialog, setReplyDialog] = React.useState(false);
  const [dataComment, setDataComment] = React.useState([]);
  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    if (property !== "avatar") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      let sort = isAsc ? "desc" : "asc";
      let sortExpression = property + " " + sort;

      GetListAll(
        undefined,
        undefined,
        undefined,
        undefined,
        page + 1,
        rowsPerPage,
      );
    }
  };
  const totalPage = Math.ceil(totalItemCount / rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    GetListAll(
      undefined,
      undefined,
      undefined,
      undefined,
      newPage,
      rowsPerPage,
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    console.log(event.target.value);
    GetListAll(
      undefined,
      undefined,
      undefined,
      undefined,
      1,
      event.target.value,
    );
  };
  const onSaveAddReply = () => {
    if (!replyText || !reflectPostId) {
      return;
    }
    const item = {
      reflectPostId,
      content: replyText,
    };
    if(!checkEdit) {
      postsAction
      .CreateReplyCommentNoCommentId(item)
      .then((res) => {
        if (res && res.content) {
          const data = [...postsModels];
          const tmp = data.find((item) => item.id === reflectPostId);
          const tmp1 = data.filter((item) => item.id !== reflectPostId);
          if (tmp) {
            tmp.repReflects.push(item);
            tmp1.unshift(tmp);
            setPostsModels(tmp1);
          }
          setReplyDialog(false);
        }
      })
      .catch((err) => console.log(err));
    } else {
      setReplyDialog(false);
      return;
    }
   
  };
  console.log(postsModels);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, postsModels.length - page * rowsPerPage);
    const renderActionStatus = (item) => {
      let lable = 'Chưa xử lý';
      if(item === 1) {
        lable = "Đã xử lý"
        return lable;
      }
      if(item === 2) {
        lable = "Đang xử lý"
        return lable;
      }
      return lable;
    }
    const handleChangeContent = (content) => {
      if (content === "<p><br></p>") {
        setReplyText("");
      } else {
        setReplyText(content);
      }
    };
  return (
    <div className={classes.root}>
      {isOpen && (
        <Lightbox mainSrc={imageUrl} onCloseRequest={() => setIsOpen(false)} />
      )}
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={postsModels.length}
            />
            <TableBody>
              {postsModels && postsModels.length > 0 ? (
                postsModels.map((row, index) => {
                  let replyText = "";
                  const reply =
                    row.repReflects &&
                    row.repReflects.length > 0 &&
                    row.repReflects.find((item) => !item.commentId);
                  if (reply) {
                    replyText = reply.content;
                  }
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell className="shadow-sm">{row.title}</TableCell>
                      {row?.content?.length > 150 ? (
                        <Tooltip title={row.content} arrow>
                          <TableCell>{row.content.slice(0, 150)}...</TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell>{row.content}</TableCell>
                      )}

                      <TableCell className="text-center">
                        {renderActionStatus(row.statusAction)}
                      </TableCell>
                      <TableCell>
                        <img
                          src={configuration.APIUrlDefault + row.image_Url}
                          onClick={() => {
                            setIsOpen(true);
                            setImageUrl(
                              configuration.APIUrlDefault + row.image_Url
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          onError={(e) =>
                            (e.target.src =
                              process.env.PUBLIC_URL + "/logo.png")
                          }
                          alt={row.title}
                          className="logo"
                        />
                      </TableCell>
                      <TableCell
                        cassName="text-center"
                        onClick={() => {
                          if (row.videoAttemp) {
                            setVideoUrl(row.videoAttemp);
                            setOpenVideoDialog(true);
                          }
                        }}
                      >
                        {row.videoAttemp}
                      </TableCell>
                      <TableCell cassName="text-center">
                        {row.districtName}
                      </TableCell>
                      <TableCell cassName="text-center">
                        {row.address}
                      </TableCell>

                      {replyText ? (
                        replyText.length > 150 ? (
                          <Tooltip title={replyText} arrow>
                            <TableCell>{replyText.slice(0, 150)}...  <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setCheckEdit(true)
                              setReplyText(replyText)
                              setReplyDialog(true);
                              setReflectPostId(row.id);
                            }}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip></TableCell>
                          </Tooltip>
                        ) : (
                          <TableCell>{replyText}  <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setCheckEdit(true)
                              setReplyText(replyText)
                              setReplyDialog(true);
                              setReflectPostId(row.id);
                            }}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip></TableCell>
                        )
                      ) : (
                        <TableCell style={{ minWidth: 150 }}>
                          {" "}
                          <Button
                            variant="contained"
                            className="bg-success text-white"
                            onClick={() => {
                              setCheckEdit(false)
                              setReplyDialog(true);
                              setReplyText('');
                              setReflectPostId(row.id);
                            }}
                          >
                            Trả lời
                          </Button>
                        </TableCell>
                      )}
                      <TableCell cassName="text-center">
                        <Tooltip title="Bình luận">
                          <IconButton
                            aria-label="xin-y-kien"
                            onClick={() => {
                              setReflectPostId(row.id);
                              setShowDialogComment(true);
                              setDataComment(row.comments);
                            }}
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.comments?.length || 0}
                              showZero
                            >
                              <ChatBubbleIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      <TableCell>{row.created_by}</TableCell>
                      <TableCell>
                        {dateformat(row.created_date, "dd/mm/yyyy")}
                      </TableCell>

                      <TableCell align="right" className="text-nowrap">
                        <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAction(row)}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteAction(row.id, row.xmin)}
                          >
                            <DeleteIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete ? (
                          <Tooltip title="Khôi phục">
                            <IconButton
                              aria-label="restore"
                              onClick={() => restoreAction(row.id)}
                            >
                              <RestoreIcon className="text-success" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={8}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {openVideoDialog && (
          <Dialog
            open={openVideoDialog}
            onClose={() => setOpenVideoDialog(false)}
            aria-labelledby="annoucedDialog"
            aria-describedby="annoucedDescription"
            maxWidth="md"
            fullWidth={true}
          >
            <DialogContent className="p-0">
              <div className="w-100 d-flex justify-content-center">
                <iframe width={600} height={450} src={videoUrl}></iframe>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {replyDialog && (
          <Dialog
            open={replyDialog}
            onClose={() => setReplyDialog(false)}
            aria-labelledby="annoucedDialog"
            aria-describedby="annoucedDescription"
            maxWidth="md"
            fullWidth={true}
          >
            <DialogTitle disableTypography className="border-bottom">
              <Typography variant="h6"> {`${checkEdit ? 'Chỉnh sửa' : 'Thêm'}`} câu trả lời</Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setReplyDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="p-2">
              <div className="">
              <div className="form-group">
                <SunEditor
                  enableToolbar={true}
                  showToolbar={true}
                  imageUploadSizeLimit={MaxSizeImageUpload}
                  videoFileInput={false}
                  setContents={replyText}
                  setOptions={{
                    height: "auto",
                    minHeight: 200,
                    maxHeight: 400,
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "blockquote",
                        "hiliteColor",
                        "textStyle",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                      ],
                    ],
                  }}
                  onChange={handleChangeContent}
                />
              </div>
              </div>
            </DialogContent>
            <DialogActions className="border-top">
              <Button
                type="button"
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={() => setReplyDialog(false)}
              >
                Hủy
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className="ml-2"
                onClick={() => onSaveAddReply()}
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {showDialogComment && (
          <DialogComment
            classes={classes}
            dataComment={dataComment}
            postsModels={postsModels}
            reflectPostId={reflectPostId}
            setDataComment={setDataComment}
            setPostsModels={(a) => setPostsModels(a)}
            showDialogComment={showDialogComment}
            setShowDialogComment={(a) => setShowDialogComment(a)}
          ></DialogComment>
        )}
           {totalItemCount && totalItemCount > 0 ? (
          <FooterPagination
            currentPage={page + 1}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
}
