import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Checkbox,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//--- Styles
import "./posts.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListPosts from "./list-posts/list-posts.view";
import AddPosts from "./add-posts/add-posts.view";
import EditPosts from "./edit-posts/edit-posts.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as postsAction from "../../redux/store/posts/posts.store";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import * as proviceAction from "../../redux/store/province-management/province.store";
import * as districtAction from "../../redux/store/district-management/district.store";
import * as appActions from "../../core/app.store";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Posts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [postsModels, setPostsModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    keyword: "",
    status: 3,
  });
  const [proviceSelect, setProviceSelect] = useState();
  const [districtByProvinceId, setDistrictByProvinceId] = useState();
  const [provinceId, setProvinceId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [postsId, setPostsId] = useState();
  const [postsxmin, setPostsxmin] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [isDelete, setIsDelete] = useState(0);
  const [isShowTrash, setIsShowTrash] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  useEffect(() => {
    dispatch(appActions.ShowLoading(true));
    Promise.all([GetLookupProvince()])
      .then((res) => {
        const [provinceLookupModels] = res;
        setProviceSelect(
          provinceLookupModels && provinceLookupModels.content
            ? provinceLookupModels.content
            : []
        );
        dispatch(appActions.ShowLoading(false));
      })
      .catch((err) => {
        dispatch(appActions.ShowLoading(false));
      });
  }, []);

  useEffect(() => {
    if (!provinceId) return;
    GetDistrictByProvinceId(provinceId);
  }, [provinceId]);

  const GetLookupProvince = () => {
    return new Promise((resolve, reject) => {
      proviceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const GetDistrictByProvinceId = (provinceId) => {
    if (!provinceId) return;
    return districtAction
      .GetDistrictByProvinceId(provinceId)
      .then((res) => {
        setDistrictByProvinceId(res && res.content ? res.content : []);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    GetListAll(
      searchData.keyword,
      provinceId,
      districtId,
      searchData.status,
      1,
      rowsPerPage
    );
  }, []);

  const GetListAll = (
    keyword,
    provinceId,
    districtId,
    status = null,
    pageIndex = 1,
    pageSize = rowsPerPage,
  ) => {

    setPage(pageIndex - 1);
    postsAction
      .GetListAll(
        keyword,
        provinceId,
        districtId,
        status,
        pageIndex,
        pageSize
      )
      .then((res) => {
        if (res && res.content) {
          setPostsModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
        }
      })
      .catch((err) => console.log(err));
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [postsModel, setPostsModel] = useState({});

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (row) => {
    setPostsModel(row);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (postsId, postsxmin) => {
    setPostsId(postsId);
    setPostsxmin(postsxmin);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const handleRestore = (id) => {
    postsAction.RestorePosts(id).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            provinceId,
            districtId,
            searchData.status,
            1,
            rowsPerPage
          );
          ShowNotification(
            viVN.Success.PostsRestoreSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };
  const handleDelete = () => {
    postsAction.DeletePosts(postsId, postsxmin).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            provinceId,
            districtId,
            searchData.status,
            1,
            rowsPerPage
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.PostsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (postsId, value) => {
    alert("change " + postsId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      searchData.keyword,
      provinceId,
      districtId,
      searchData.status,
      1,
      rowsPerPage
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setSearchData({
      keyword: "",
      status: 3,
    });
    GetListAll(
      searchData.keyword,
      provinceId,
      districtId,
      searchData.status,
      1,
      rowsPerPage
    );
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked ? 1 : 0);
    GetListAll(
      searchData.keyword,
      provinceId,
      districtId,
      searchData.status,
      1,
      rowsPerPage
    );
  };

  //const handleChangeStatus = (event) => {
  //    setStatus(event.target.value === 2 ? undefined : event.target.value);
  //    setSearchData({ [event.target.name]: event.target.value });
  //}

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleEmptyTrash = () => {
    postsAction.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            provinceId,
            districtId,
            searchData.status,
            1,
            rowsPerPage
          );
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.PostsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };
  const handleChangeSelect = (event) => {
    // setValue("provindeId", event.target.value);
    setProvinceId(event.target.value);
  };

  const handleChooseDistrict = (event) => {
    // setValue("districtId", event.target.value);
    setDistrictId(event.target.value);
  };
  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Phản ánh hiện trường
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div className="p-3" style={{ width: "30rem" }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseFilter}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </Popover>
        </h1>
      
      </div>
      <div>
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex">
            <div className="form-group">
              <TextField
                className="w-100"
                name="keyword"
                variant="outlined"
                placeholder="Từ khóa"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>

            <div className="form-group ml-2">
              <Select onChange={handleChangeSelect} variant="outlined" style={{ minWidth: 170 }} value={provinceId} displayEmpty
                placeholder="Tỉnh/ thành phố">
                <MenuItem value="" disabled>Chọn Tỉnh/ thành phố</MenuItem>
                {proviceSelect && proviceSelect.length > 0 ? (
                  proviceSelect.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Không có tỉnh thành nào</MenuItem>
                )}
              </Select>
            </div>
            <div className="form-group ml-2">
              <Select
                disabled={provinceId ? false : true}
                variant="outlined"
                style={{ minWidth: 170 }}
                value={districtId}
                displayEmpty
                placeholder="Quận - huyện"
                onChange={handleChooseDistrict}
              >
                <MenuItem value="" disabled>Chọn Quận-huyện</MenuItem>
                {districtByProvinceId && districtByProvinceId.length > 0 ? (
                  districtByProvinceId.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Không có quận huyện nào</MenuItem>
                )}
              </Select>
            </div>
            <div className="form-group ml-2">
              <Select
                variant="outlined"
                name="status"
                placeholder="Trạng thái"
                value={searchData.status}
                onChange={handleChangeSearchForm}
              >
                <MenuItem value={3}>----------------</MenuItem>
                <MenuItem value={0}>Đã xử lý</MenuItem>
                <MenuItem value={1}>Chưa xử lý</MenuItem>
                <MenuItem value={2}>Đang xử lý</MenuItem>
              </Select>
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit}>
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
      </div>
      {postsModels ? (
        <ListPosts
          totalItemCount={totalItemCount}
          postsModels={postsModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          setPostsModels={setPostsModels}
          setPostsModel={setPostsModel}
        />
      ) : (
        ""
      )}
      {/* {openAddDialog && (
        <AddPosts
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )} */}

      {openEditDialog && (
        <EditPosts
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          postsModel={postsModel}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
    </div>
  );
}
